/******************************************************************************/
/* App CSS */

@font-face {
  font-family: EtherScapesFont;
  src:
    local("EtherScape Font"),
    local("EtherScape-Font"),
    url("fonts/regular.ttf");
}

html, body {
  --color-font: #e8ffee;
  --color-special: #78db97;
  --color-background: #5c5b77;
  --color-header: #2d293d;
  --color-header-alpha: #e8ffee60;
  
  margin: 0;
  padding: 0;
  height: 100%;

  color: var(--color-font);
  background: var(--color-background);
  font-family: EtherScapesFont;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

/* #root level sibling for modal popups. */
#modal {
  position: absolute;
  z-index: 999;
}
/* conditionally displayed item, blank screen, fullscreen etc. */
.modal {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: -100vh;
  left: 0;
  display: flex;
  background-color: #000000D0;
  justify-content: center;
  align-items: center;
}
/* modal body container */
.modal-body {
  max-width: 70%;
  height: auto;
  padding: 20px;
  background-color: var(--color-background);
}

.error {
  height: 100%;
  display: flex;
  padding: 30px;
  justify-content: center;
  align-items: center;
}

.App {
  display: flex;
  flex: 1;
  flex-direction: column;
}

/*
 *  Common rules.
 */

.col {
  display: flex;
  flex-direction: row;
}
.grow {
  flex-grow: 1; 
}
.float {
  padding: 4px;
  background-color: #ecc60db0;
}
.underline {
  padding-bottom: 5px;
  border-bottom: 2px solid var(--color-header);
  margin-bottom: 5px;
}
.spacer {
  width: 30px;
}

/*
 *  Header rules.
 */
.App-header {
  display: flex;
  flex-direction: row;
  height: 50px;
  background-color: var(--color-header);
  justify-content: flex-start;
  align-items: center;
}

.link,
.App-header a {
  margin: 20px;
  color: var(--color-font);
  cursor: pointer;
  text-decoration: none;
}
.App-header a:visited {
  color: var(--color-font);
}

.link, .link:visited {
  color: var(--color-special);
}

.App-header a.isActive,
.App-header a:hover {
  color: var(--color-special);
}
.App-header .balance {
  color: var(--color-font);
  margin-right: 20px;
}

/*
 *  Body spans rest of space.
 */
.App-body {
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden;
}
.App-scroll {
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
}

/******************************************************************************/
 
/*
 *  Home
 */

 .info-main, .error-main {
  justify-content: center;
  align-items: center;
}
.info-main,
.error-main,
.Home-main {
  height: 100%;
  max-width: 720px;
  margin: 0 auto;
  
  display: flex;
  flex-direction: column;
}
.Home-main>.title {
  margin-top: 30px;
  width: 100%;
  margin-bottom: 5px;
  text-align: center;
  font-size: 13pt;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Home-main>.title span {
  margin: 5px;
  user-select: none;
}

a.clickable,
.clickable {
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  color: var(--color-special);
  
}
.Home-main>.title>span.invalid {
  visibility: hidden;
}
.Home-main>.title .spacer {
  width: 50px;
}
a.clickable:visited {
  color: var(--color-special);
  text-decoration: none;
}

.canvas {
  width: 100%;
  margin: 0 auto 0 auto;
  border: 4px solid var(--color-header);
  background-color: var(--color-header);
}


.puzzle-name {
  font-size: 12pt;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  
  width: 100%;
  text-align: center;
  background: var(--color-header);
  border: 4px solid var(--color-header);
  padding-top: 7px;
  padding-bottom: 7px;
  margin-top: 10px;
}
.puzzle-name-details {
  color: var(--color-font);
  display: flex;
  flex-direction: column;
  text-align: start;
  padding-top: 10px;
}
.puzzle-name-details>div {
  margin: 5px;
  padding: 5px;
}

.puzzle-name-input {
  display: flex;
}
.puzzle-name-input>input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--color-background);
  font-size: 14pt;
  color: var(--color-special);
  flex-grow: 1;
  margin: 5px 10px;
}
.puzzle-name-input>input:focus {
  border: none;	
  outline: none;
  border-bottom:1px solid var(--color-special);	
}


.puzzle-toolbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 4px;
  padding-right: 4px;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 8px;
  background: var(--color-header);
}
.puzzle-toolbar img {
  margin-top: 5px;
  margin-right: 10px;
  width: 32px;
}
.puzzle-toolbar>.col.clickable {
  align-items: center;
  margin-right: 10px;
}

.puzzle-author:before { content: "named by: "; }
.puzzle-author {
  margin-top: 10px;
  margin-left: 10px;
  color: var(--color-font); 
  font-size: 12pt;
  margin-left: 8vw;
  width: 100%;
  text-align: left;
}

.token-table {
  margin: 25px auto;
  max-width: 100%;
}

.token-table .th {
  display: flex;
  flex-direction: row;
  flex-basis: 12;
}
.token-table .th>div {
  padding: 5px;
  
  display: flex;
  justify-content: center;
  align-items: center;
}
.token-table .th>div:nth-child(1) {
  flex: 3;
  justify-content: flex-start;
  padding-left: 20px;
}
.token-table .th>div:nth-child(1)>img{
  margin-right: 15px;
}
.token-table .th>div:nth-child(2) {
  flex: 2;
}
.token-table .th>div:nth-child(3) {
  flex: 2;
}
.token-table .th>div:nth-child(4) {
  flex: 5;
}
.token-table .th img {
  padding: 5px;
  background-color: var(--color-header-alpha);
  border: 1px solid var(--color-header);
}

.token-table>.shard-row {
  cursor: pointer;
  min-height: 50px;
}
.token-table>.shard-row:hover {
  background-color: var(--color-header);
  color: var(--color-special);
}

/******************************************************************************/

.About-main {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  max-width: 90%;
  margin: 0 auto;
}


/******************************************************************************/
.shard-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shard-main {
  display: flex;
  flex-direction: column;
}

.shard-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 18px;
}

.shard-main .bg {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 60%;
  margin: 0 auto;
}

.shard-main .bg>img {
  padding: 20px;
  max-width: 420px;
  max-height: 300px;
  height: auto;
  padding: 12px;
  background-color: var(--color-header);
}

/******************************************************************************/

.social-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}
.social-link>div {
  display: flex;
  flex-direction: row;
  align-items: center; 
}
.social-link>div>a {
  text-decoration: none;
  color: var(--color-special);
}
.social-link>div>a:visited {
  color: var(--color-special);
}
.social-link>div>a>span {
  margin-left: 10px;
  position: relative;
  top: -3px;
}
.social-link>div>a>img {
  width: 24px;
  height: 24px;
  margin-left: 20px;
}

.tooltips {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: visible;
}
#networkInfoTooltip,
#infoShardTooltip,
#infoPuzzleTooltip,
#infoEscapeTooltip,
#claimTooltip,
#balanceTooltip,
#solveTooltip,
#packTooltip {
  background-color: var(--color-header);
  opacity: 1;
  color: var(--color-font);
  border-radius: 5px;
  border: 2px solid var(--color-font)
}

/******************************************************************************/

.footer {
  display: flex;
  width: calc(100vw - 60px);
  height: 50px;
  background-color: var(--color-background);
  color: var(--color-font);
  padding: 0 30px;
  align-items: center;
}
.footer img {
  width: 26px;
  margin: 0 15px;
}
.footer a:hover>:before {
  position: absolute;
  bottom: 50px;
  color: var(--color-font);
  font-size: 10pt;
}
.footer .opensea:hover>:before {
  content: "OpenSea";
}
.footer .github:hover>:before {
  content: "GitHub";
}
.footer .discord:hover>:before {
  content: "Discord";
}
.footer .reddit:hover>:before {
  content: "Reddit";
}

.faq-main {
  display: flex;
  flex-direction: column;
  width: 85%;
  max-width: 800px;
  margin: 40px auto;
  height: 100%;
}

.faq-section {
  display: flex;
  flex-direction: column;
  background-color: var(--color-header);
  margin-bottom: 10px;
}

.faq-question {
  display: flex;
  align-items: flex-start;
  width: 100%;
  font-size: 14pt;
  margin: 20px;
  color: var(--color-special);
  cursor: pointer;
}
.faq-body {
  padding: 20px;
  padding-top: 0;
}
.faq-body a {
  text-decoration: none;
  color: var(--color-special);
}
.faq-body a:before {
  content: " ";
}
.faq-body a:visited {
  color: var(--color-special);
}
.faq-body p {
  line-height: 1.3em;
}